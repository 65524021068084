type SchoolIntro = {
  chinese_name: string,
  english_name: string,
  logo: string,
  link: string
}

export const schools: SchoolIntro[] = [
  {
    chinese_name: '國立政治大學',
    english_name: 'National Chengchi University',
    logo: 'logo_NCCU.png',
    link: 'https://www.nccu.edu.tw'
  },
  {
    chinese_name: '國立清華大學',
    english_name: 'National Tsing Hua University',
    logo: 'logo_NTHU.png',
    link: 'https://www.nthu.edu.tw'
  },
  {
    chinese_name: '國立臺灣大學',
    english_name: 'National Taiwan University',
    logo: 'logo_NTU.jpg',
    link: 'https://www.ntu.edu.tw'
  },
  {
    chinese_name: '國立臺灣師範大學',
    english_name: 'National Taiwan Normal University',
    logo: 'logo_NTNU.png',
    link: 'https://www.ntnu.edu.tw'
  },
  {
    chinese_name: '國立成功大學',
    english_name: 'National Cheng Kung Univeristy',
    logo: 'logo_NCKU.svg',
    link: 'http://www.ncku.edu.tw'
  },
  {
    chinese_name: '國立陽明交通大學',
    english_name: 'National Yang Ming Chiao Tung University',
    logo: 'logo_NYCU.png',
    link: 'https://www.nycu.edu.tw'
  },
  {
    chinese_name: '國立中山大學',
    english_name: 'National Sun Yat-sen University',
    logo: 'logo_NSYSU.png',
    link: 'https://www.nsysu.edu.tw'
  },
  {
    chinese_name: '國立東華大學',
    english_name: 'National Dong Hwa University',
    logo: 'logo_PTA.png',
    link: 'https://www.ndhu.edu.tw'
  },
  {
    chinese_name: '國立暨南國際大學',
    english_name: 'National Chi Nan University',
    logo: 'logo_NCNU.png',
    link: 'https://www.ncnu.edu.tw'
  },
  {
    chinese_name: '國立臺灣科技大學',
    english_name: 'National Taiwan University of Science and Technology',
    logo: 'logo_NTUST.png',
    link: 'https://www.ntust.edu.tw'
  },
  {
    chinese_name: '國立雲林科技大學',
    english_name: 'National Yunlin University of Science and Technology',
    logo: 'logo_NYUST.png',
    link: 'https://www.yuntech.edu.tw'
  },
  {
    chinese_name: '國立虎尾科技大學',
    english_name: 'National Formosa University',
    logo: 'logo_NFU.png',
    link: 'https://www.nfu.edu.tw'
  },
  {
    chinese_name: '國立臺灣體育運動大學',
    english_name: 'National Taiwan University of Sport',
    logo: 'logo_NTUS.png',
    link: 'https://www.ntus.edu.tw'
  },
  {
    chinese_name: '國立高雄科技大學',
    english_name: 'National Kaohsiung University of Science and Technology',
    logo: 'logo_NKUST.png',
    link: 'https://www.nkust.edu.tw'
  },
  {
    chinese_name: '輔仁大學',
    english_name: 'Fu Jen Catholic University',
    logo: 'logo_FJCU.png',
    link: 'https://www.fju.edu.tw'
  },
  {
    chinese_name: '東吳大學',
    english_name: 'Soochow University',
    logo: 'logo_SU.png',
    link: 'https://www.scu.edu.tw'
  },
  {
    chinese_name: '靜宜大學',
    english_name: 'Providence University',
    logo: 'logo_PU.png',
    link: 'https://www.pu.edu.tw'
  },
  {
    chinese_name: '臺北醫學大學',
    english_name: 'Taipei Medical University',
    logo: 'logo_TMU.png',
    link: 'https://www.tmu.edu.tw'
  },
  {
    chinese_name: '長榮大學',
    english_name: 'Chang Jung Christian University',
    logo: 'logo_CJCU.png',
    link: 'https://www.cjcu.edu.tw'
  },
  {
    chinese_name: '正修科技大學',
    english_name: 'Cheng Shiu University',
    logo: 'logo_CSU.svg',
    link: 'https://www.csu.edu.tw'
  },
  {
    chinese_name: '國立中興大學',
    english_name: 'National Chung Hsing University',
    logo: 'logo_NCHU.png',
    link: 'https://www.nchu.edu.tw'
  },
  {
    chinese_name: '國立中央大學',
    english_name: 'National Central University',
    logo: 'logo_NCU.png',
    link: 'https://www.ncu.edu.tw '
  },
  {
    chinese_name: '國立中正大學',
    english_name: 'National Chung Cheng University',
    logo: 'logo_CCU.png',
    link: 'https://www.ccu.edu.tw'
  },
  {
    chinese_name: '國立高雄師範大學',
    english_name: 'National Kaohsiung Normal University',
    logo: 'logo_NKNU.png',
    link: 'https://w3.nknu.edu.tw'
  },
  {
    chinese_name: '國立彰化師範大學',
    english_name: 'National Changhua University of Education',
    logo: 'logo_NCUE.png',
    link: 'https://www.ncue.edu.tw'
  },
  {
    chinese_name: '國立臺北大學',
    english_name: 'National Taipei University',
    logo: 'logo_NTPU.png',
    link: 'https://new.ntpu.edu.tw'
  },
  {
    chinese_name: '國立嘉義大學',
    english_name: 'National Chiayi University',
    logo: 'logo_NCYU.png',
    link: 'https://www.ncyu.edu.tw'
  },
  {
    chinese_name: '國立高雄大學',
    english_name: 'National University of Kaohsiung',
    logo: 'logo_NUK.png',
    link: 'https://www.nuk.edu.tw'
  },
  {
    chinese_name: '國立屏東科技大學',
    english_name: 'National Pingtung University of Science and Technology',
    logo: 'logo_NPUST.png',
    link: 'https://wp.npust.edu.tw'
  },
  {
    chinese_name: '國立宜蘭大學',
    english_name: 'National Ilan University',
    logo: 'logo_NIU.png',
    link: 'https://www.niu.edu.tw'
  },
  {
    chinese_name: '國立臺南大學',
    english_name: 'National University of Tainan',
    logo: 'logo_NUTN.png',
    link: 'https://www.nutn.edu.tw'
  },
  {
    chinese_name: '國立臺中教育大學',
    english_name: 'National Taichung University of Education',
    logo: 'logo_NTCU.png',
    link: 'http://www.ntcu.edu.tw'
  },
  {
    chinese_name: '國立勤益科技大學',
    english_name: 'National Chin-Yi University of Technology',
    logo: 'logo_NCUT.png',
    link: 'https://www.ncut.edu.tw'
  },
  {
    chinese_name: '國立金門大學',
    english_name: 'National Quemoy University',
    logo: 'logo_NQU.png',
    link: 'https://www.nqu.edu.tw'
  },
  {
    chinese_name: '國立臺中科技大學',
    english_name: 'National Taichung University of Science and Technology',
    logo: 'logo_NUTC.png',
    link: 'https://www.nutc.edu.tw'
  },
  {
    chinese_name: '國立臺北商業大學',
    english_name: 'National Taipei University of Business',
    logo: 'logo_NTUB.png',
    link: 'https://www.ntub.edu.tw'
  },
  {
    chinese_name: '國立臺南護理專科學校',
    english_name: 'National Tainan Junior College of Nursing',
    logo: 'logo_NTIN.png',
    link: 'https://www.ntin.edu.tw'
  },
  {
    chinese_name: '東海大學',
    english_name: 'Tunghai University',
    logo: 'logo_THU.png',
    link: 'https://www.thu.edu.tw'
  },
  {
    chinese_name: '中原大學',
    english_name: 'Chung Yuan Christian University',
    logo: 'logo_CYCU.png',
    link: 'https://www.cycu.edu.tw'
  },
  {
    chinese_name: '淡江大學',
    english_name: 'Tamkang University',
    logo: 'logo_TKU.png',
    link: 'https://www.tku.edu.tw'
  },
  {
    chinese_name: '元智大學',
    english_name: 'Yuan Ze University',
    logo: 'logo_YZU.png',
    link: 'https://www.yzu.edu.tw'
  },
  {
    chinese_name: '義守大學',
    english_name: 'I-SHOU University',
    logo: 'logo_ISU.png',
    link: 'https://www.isu.edu.tw'
  },
  {
    chinese_name: '銘傳大學',
    english_name: 'Ming Chuan University, MCU',
    logo: 'logo_MCU.png',
    link: 'https://web.mcu.edu.tw'
  },
  {
    chinese_name: '實踐大學',
    english_name: 'Shih Chien University Kaohsiung Campus',
    logo: 'logo_USC.png',
    link: 'https://www.usc.edu.tw'
  },
  {
    chinese_name: '朝陽科技大學',
    english_name: 'Chaoyang University of Technology',
    logo: 'logo_CYUT.png',
    link: 'https://www.cyut.edu.tw'
  },
  {
    chinese_name: '南華大學',
    english_name: 'Nanhua University',
    logo: 'logo_NHU.png',
    link: 'https://www.nhu.edu.tw'
  },
  {
    chinese_name: '大同大學',
    english_name: 'Tatung University',
    logo: 'logo_TTU.png',
    link: 'https://www.ttu.edu.tw'
  },
  {
    chinese_name: '崑山科技大學',
    english_name: 'Kun Shan University',
    logo: 'logo_KSU.png',
    link: 'https://www.ksu.edu.tw'
  },
  {
    chinese_name: '龍華科技大學',
    english_name: 'Lunghwa University of Science and Technology',
    logo: 'logo_LHU.png',
    link: 'https://www.lhu.edu.tw'
  },
  {
    chinese_name: '輔英科技大學',
    english_name: 'Fooyin University',
    logo: 'logo_FYU.png',
    link: 'https://www.fy.edu.tw'
  },
  {
    chinese_name: '明新科技大學',
    english_name: 'Minghsin University of Science and Technology',
    logo: 'logo_MUST.png',
    link: 'https://www.must.edu.tw'
  },
  {
    chinese_name: '弘光科技大學',
    english_name: 'Hungkuang University',
    logo: 'logo_HKU.png',
    link: 'https://www.hk.edu.tw'
  },
  {
    chinese_name: '中國醫藥大學',
    english_name: 'China Medical University',
    logo: 'logo_CMU.png',
    link: 'https://www.cmu.edu.tw'
  },
  {
    chinese_name: '玄奘大學',
    english_name: 'Hsuan Chuang University',
    logo: 'logo_HCU.png',
    link: 'https://www.hcu.edu.tw'
  },
  {
    chinese_name: '亞洲大學',
    english_name: 'Asia University',
    logo: 'logo_AU.png',
    link: 'https://www.asia.edu.tw'
  },
  {
    chinese_name: '元培醫事科技大學',
    english_name: 'Yuanpei University of Medical Technology',
    logo: 'logo_YPU.png',
    link: 'https://www.ypu.edu.tw'
  },
  {
    chinese_name: '德明財經科技大學',
    english_name: 'Takming University of Science and Technology',
    logo: 'logo_TMUST.png',
    link: 'https://www.takming.edu.tw'
  },
  {
    chinese_name: '敏實科技大學',
    english_name: 'Ta Hwa University of Science and Technology',
    logo: 'logo_MITUST.png',
    link: 'https://www.mitust.edu.tw'
  },
  {
    chinese_name: '文藻外語大學',
    english_name: 'Wenzao Ursuline University of Languages',
    logo: 'logo_WZU.png',
    link: 'https://www.wzu.edu.tw'
  },
  {
    chinese_name: '亞東科技大學',
    english_name: 'Asia Eastern University of Science and Technology',
    logo: 'logo_AEUST.png',
    link: 'https://www.aeust.edu.tw'
  },
  {
    chinese_name: '國立臺北教育大學',
    english_name: 'National Taipei University of Education',
    logo: 'logo_NTUE.png',
    link: 'https://www.ntue.edu.tw/'
  },
  {
    chinese_name: '明志科技大學',
    english_name: 'Ming Chi University of Technology',
    logo: 'logo_MCUT.png',
    link: 'https://www.mcut.edu.tw/'
  },
  {
    chinese_name: '健行科技大學',
    english_name: 'Chien Hsin University of Science and Technology',
    logo: 'logo_UCH.png',
    link: 'https://www.uch.edu.tw/'
  },
  {
    chinese_name: '國立屏東大學',
    english_name: 'National Pingtung University',
    logo: 'logo_NPTU.png',
    link: 'https://www.nptu.edu.tw/'
  },
  {
    chinese_name: '佛光大學',
    english_name: 'Fo Guang University',
    logo: 'logo_FGU.png',
    link: 'https://www.fgu.edu.tw'
  },
  {
    chinese_name: '臺北市立大學',
    english_name: 'University of Taipei',
    logo: 'logo_UTAIPEI.png',
    link: 'https://www.utaipei.edu.tw/'
  },
  {
    chinese_name: '樹德科技大學',
    english_name: 'Shu Te University',
    logo: 'logo_STU.png',
    link: 'https://www.stu.edu.tw/'
  },
  {
    chinese_name: '慈濟大學',
    english_name: 'Tzu Chi University',
    logo: 'logo_TCU.png',
    link: 'https://www.tcu.edu.tw/'
  },
  {
    chinese_name: '長庚大學',
    english_name: 'Chang Gung University',
    logo: 'logo_CGU.png',
    link: 'https://www.cgu.edu.tw/'
  },
  {
    chinese_name: '國立臺北藝術大學',
    english_name: 'Taipei National University of the Arts',
    logo: 'logo_TNUA.png',
    link: 'https://w3.tnua.edu.tw/'
  },
  {
    chinese_name: '致理科技大學',
    english_name: 'Chihlee University of Technology',
    logo: 'logo_CLUT.png',
    link: 'https://www.chihlee.edu.tw/'
  },
  {
    chinese_name: '國立臺東大學',
    english_name: 'National Taitung University',
    logo: 'logo_NTTU.png',
    link: 'https://www.nttu.edu.tw/'
  },
  {
    chinese_name: '康寧大學',
    english_name: 'University of Kang Ning',
    logo: 'logo_UKN.png',
    link: 'https://www.ukn.edu.tw/'
  },
  {
    chinese_name: '世新大學',
    english_name: 'Shih Hsin University',
    logo: 'logo_SHU.png',
    link: 'https://www.shu.edu.tw/'
  },
  {
    chinese_name: '國立聯合大學',
    english_name: 'National United University',
    logo: 'logo_NUU.png',
    link: 'https://www.nuu.edu.tw/'
  }
]