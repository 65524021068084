import React from 'react';
import { Flex, Text, Stack, HStack, Link, Button,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter,
  useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface ModalProps {
  isOpen: boolean,
  onClose: () => void,
}

export const ContactModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='sm' isCentered>
      <ModalOverlay />
      <ModalContent py='16px' borderRadius='20px'>
        <ModalHeader pt='0' color='text.main'>{t('footer.contact')}</ModalHeader>
        <ModalBody pt='8px'>
          {t('about.contactPerson').split('\n').map((text, idx) =>
            <Text key={idx} textStyle='normal_md' color='grey.700'>{text}</Text>
          )}
        </ModalBody>
        <ModalFooter pb='0' display='flex' justifyContent='end'>
          <Button variant='primary' px='12px' py='8px' onClick={onClose} >
            {t('modal.confirm')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const Footer: React.FC = () => { 
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Stack as='footer' position='absolute' bottom='0' right='0' w='100%' p='15px 32px 24px 32px' spacing='24px'
      borderTop='1px solid' borderColor='grey.light'
    >
      <HStack spacing={8}>
      <a href="#B" title="下方功能區塊" id="AB" accessKey="B" style={{}}>:::</a>
        <NavLink exact to={'/sitemap'}>
          <Text textStyle='normal_sm'>{t('footer.sitemap')}</Text>
        </NavLink>
        <Link href='#' textStyle='normal_sm' cursor='pointer' onClick={onOpen}>
          {t('footer.contact')}
        </Link>
        <NavLink exact to={'/privacy'}>
          <Text textStyle='normal_sm'>{t('footer.privacy')}</Text>
        </NavLink>
        <ContactModal
          isOpen={isOpen}
          onClose={onClose}
        />
        <Link textStyle='normal_sm'  textDecoration='none'
          href='https://www.edu.tw' target='_blank'
        >
          {t('footer.eduWebsite')}
        </Link>
      </HStack>
      <Flex w='100%' justify='space-between'>
        <Stack spacing='2px'>
          <Text color='#4F6470' textStyle='normal_sm'>
            {t('footer.copyright')}
          </Text>
          <Text color='#4F6470' textStyle='normal_sm'>
            {t('footer.maintenance')}
          </Text>
          <Text color='#4F6470' textStyle='normal_sm'>
            {t('footer.monitor')}
          </Text>
        </Stack>
        <Text color='#4F6470' textStyle='normal_sm' alignSelf='end'>
          {t('footer.update')}
        </Text>
      </Flex>
    </Stack>
  )
}

export default Footer;